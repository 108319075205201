<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="CompassIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Proposta Ferie</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          ID Proposta Ferie:
        </p>
        <h3 class="mb-1">
          #{{ justificationproposalData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Stato:
        </p>
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveStatusIcon(justificationproposalData.status)"
            size="18"
            class="mr-50"
            :class="resolveStatusColor(justificationproposalData.status)"
          />
          <span class="align-text-top text-capitalize">{{ resolveStatusName(justificationproposalData.status) }}</span>
        </div>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Data di Inizio:
        </p>
        <h3 class="mb-1">
          {{ parseDate(justificationproposalData.start_day) }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Data di Fine:
        </p>
        <h3 class="mb-1">
          {{ parseDate(justificationproposalData.end_day) }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Giorni Massimi:
        </p>
        <h3 class="mb-1">
          {{ justificationproposalData.max_days }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Giorni Richiesti:
        </p>
        <h3 class="mb-1">
          {{ justificationproposalData.selected_days }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Destinatario:
        </p>
        <div class="text-nowrap">
          <div class="d-flex align-items-center">
            <b-avatar
              size="25"
              :src="justificationproposalData.user.avatar_file ? mediaUrl + justificationproposalData.user.avatar_file : ''"
              :text="avatarText(justificationproposalData.user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justificationproposalData.user.role_code)}`"
              class="mr-1"
            />
            <b-link>
              #{{ justificationproposalData.user.anagrafico_id }} - {{ justificationproposalData.user.display_name }}
            </b-link>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="justificationproposalData.note"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Note:
        </p>
        <h4 class="mb-1">
          {{ justificationproposalData.note }}
        </h4>
      </b-col>
    </b-row>
    <b-row v-if="justificationproposalData.status == 'waiting'">
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          variant="outline-danger"
          class="ml-1"
          @click="
            $bvModal.show('delete-modal')
            selectedJustificationproposal = justificationproposalData.id
          "
        >
          Annulla
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="delete-modal"
      title="Annulla Proposta Ferie"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Annulla Proposta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler annullare definitivamente questa Proposta Ferie?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteJustificationproposal(selectedJustificationproposal)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BLink, BModal, BListGroup, BListGroupItem, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BModal,
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  props: {
    justificationproposalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedJustificationproposal: null,
      avatarText,
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
    }
  },
  methods: {
    parseDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'info'
      if (role === 'hr') return 'warning'
      if (role === 'reception_hub') return 'warning'
      if (role === 'reception_colle') return 'warning'
      if (role === 'accountant') return 'warning'
      if (role === 'admin') return 'danger'
      return 'danger'
    },
    resolveStatusIcon(status) {
      if (status === 'approved') return 'CheckIcon'
      if (status === 'waiting') return 'PauseIcon'
      if (status === 'rejected') return 'XIcon'
      if (status === 'deleted') return 'TrashIcon'
      return 'PauseIcon'
    },
    resolveStatusName(status) {
      if (status === 'approved') return 'Approvata'
      if (status === 'waiting') return 'In Attesa'
      if (status === 'rejected') return 'Rifiutata'
      if (status === 'deleted') return 'Annullata'
      return ''
    },
    resolveStatusColor(status) {
      if (status === 'approved') return 'text-success'
      if (status === 'waiting') return 'text-secondary'
      if (status === 'rejected') return 'text-warning'
      if (status === 'deleted') return 'text-danger'
      return 'text-warning'
    },
    goBack() {
      this.$router.go(-1)
    },
    deleteJustificationproposal(id) {
      store
        .dispatch('app-justificationproposals/deleteJustificationproposal', { id })
        .then(() => {
          this.selectedJustificationproposal = null
          this.$router.replace({ name: 'apps-justificationproposals-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Proposta Ferie #${id} annullata con successo`,
                icon: 'CompassIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
